import { Button, Snackbar } from '@mui/material';
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react';

export const ServiceWorker = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      if (r) {
        setInterval(async () => {
          // inspired by https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html#handling-edge-cases
          // to prevent "Failed to update a ServiceWorker for scope ('https://app.reef.ai/')" errors
          if (r.installing || !navigator) {
            return;
          }
          if ('connection' in navigator && !navigator.onLine) {
            return;
          }

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });

          if (resp?.status === 200) {
            await r.update();
          }
        }, 60 * 1000);
      }
    },
  });

  // FIXME: update copy + designs here
  return (
    <Snackbar
      open={needRefresh}
      // anchor in bottom-middle to not visually block the profile menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message="A new version of Reef is available."
      action={<Button onClick={() => updateServiceWorker()}>Update</Button>}
    />
  );
};
